import { Component, OnInit } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { environment } from '@env/environment';
import moment from 'moment';
import { SubCategory } from '@app/core/data/SubCategory';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

import { Storage } from '@ionic/storage';
import { LoadingService } from '../services/loading.service';
@Component({
  selector: 'app-featured-events-row',
  templateUrl: require('template/' + environment.template + '/html/featured-events-row.component.html'),
  styles: [require('template/' + environment.template + '/styles/featured-events-row.component.scss')]
})
export class FeaturedEventsRowComponent implements OnInit {

  public environment = environment;
  events!: CMSEvent[];
  listings!: SubCategory[];
  filteredListings!: Map<string,boolean>;
  visibleListings!: SubCategory[];

  language = this.translate.currentLang;
  constructor(
    private router: Router,
    public storage: Storage,
    private loading: LoadingService,
    public translate: TranslateService,
    private dataController: CMSDataControllerService,
    ) {
      storage.get('language').then(lang => {
      console.log(lang)
      translate.setDefaultLang(lang || 'en')
    })
  }

  async ngOnInit() {
    await this.loading.presentLoading();
    let map = new Map();
    this.dataController.getEvents2({ domainId: environment.cms.domainId, dataSyndication: false }).subscribe(list => {
      // filter events out so only not expired events will show up
      const now = new Date();
      this.events = list.filter(a => {
        return this.inRange(now, null, a.StartDate, a.EndDate);
        // if (a.EndDate) {
        //   const aDate = moment(a.EndDate).valueOf();
        //   const now = moment(new Date()).valueOf();
        //   return now <= aDate;
        // }
        // return false;
      });
      // this.events = list;


      // order by date
      this.events.sort((a, b) => {
        const aDate = moment(a.StartDate).valueOf();
        const bDate = moment(b.StartDate).valueOf();
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate ) {
          return 1;
        }
        return 0;
      });
      this.events = this.events.splice(0,8);
      this.loading.dismiss();
      console.log(this.events)
    });
  }

  format(date: string, format: string): string {
    return moment(date).format(format);
  }

  onClick(event){
    console.log(event);
    // this.router.navigate(['/tabs/category'], { queryParams: { t: event.SubCategoryName, subCategoryId: event.SubCategoryID } });
    this.router.navigate([`tabs/events/${event.EventId}`])
  }

  inRange(rangeStart: Date, rangeEnd?: Date, eventStartDate?: string, eventEndDate?: string): boolean {
    if (!eventStartDate || !eventEndDate) {
      // an event it's considered candidate to check his range if has a non-null start & end dates
      return false;
    }
    if(new Date(eventStartDate).getTime() >= rangeStart.getTime()){
      return true
    }

    return false;
  }

}
