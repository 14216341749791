import { settings as settings } from '../environments/settings';
export const environment = {
  ...settings,
  template: 'pwa-1.1',
  theme: {
    name: 'Laguna-Beach',
    appName: 'Laguna-Beach',
    productionHostName: 'trueomni-laguna-beach.web.app',
    stagingHostName: 'staging-laguna-beach.web.app',
    devHostName: 'dev-laguna-beach.web.app',
    appCode: 'LAGU'
  },
  cms: {
    domainId: 1092,
    accountId: 1092,
    channelId: 1493,
    deviceId: 1057,
    formId: 0,
    postalCode: '92651',
    bandwangosPageId: 0,
    visitorCenterId: 0,
    main_coordinates: {
      latitude: settings.site.latitude,
      longitude: settings.site.longitude,
    },
    navigation: {
      avatar_enabled: false,
      homePageId: 8443,
      itemsPerRowCategory: 2
    }
  },
  regions:{
    enabled: false,
    regionsPageId: 0
  },
  coreAuthLib: {
    base_url: 'https://usersapi.mobimanage.com',
    client_secret: '7K0ZIXiBvUKrcTzQRxWc8A',
  },
  firebase: {
    apiKey: "AIzaSyCouM41HhyxK4Ny4R_FyrSUa3QqZqXpulI",
    authDomain: "trueomni-laguna-beach.firebaseapp.com",
    databaseURL: "",
    projectId: "trueomni-laguna-beach",
    storageBucket: "trueomni-laguna-beach.appspot.com",
    messagingSenderId: "180229164027",
    appId: "1:180229164027:web:bbd51d06676e87f847995e",
    measurementId: "G-L14Q0EM949"
  },
  messagesSDK: {
    client_id: 1608,
    client_secret: 'FnheGDnrA0G53M5zbpgthw',
    base_url: 'https://pushapi.mobimanage.com'
  },
  socialWall: {
    client_id: '',
    client_secret: '2bGGWUbs9begrFJHZPBApqfSBEq7AGQ4spYUZ4m6=',
    url: 'https://wall.crowdriff.com/live/24206c2e-3bba-407a-b649-8abd93f1b40b'
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  google: {
    client_id: '180229164027-3jrc6drqribujrbn6t88jruuqsom3517.apps.googleusercontent.com',
  },
  facebook: {
    app_id: '542774128620010',
    version: 'v21.0'
  },
  batuta: {
    api_key: 'e8e98196453d6f0bd4ffd6553d56a829',
    base_url: 'http://battuta.medunes.net/api/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
  ItineraryBuilder:{
    base_url: 'https://lagunabeach-ib.trueomni.com'
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
