
export class DeepLinkHelper {

    getOnboardingRssLink(): string {
        return 'walkthrough://';
    }

    getMoreMenuRssLink(): string {
        return 'more://';
    }

    getHelpRssLink(): string {
        return 'help://';
    }

    getAvatarRssLink(): string {
        return 'interests://';
    }

    getLogoutRssLink(): string {
        return 'signout://';
    }

    getHomeRssLink(): string {
        return 'home://';
    }

    getTabsRssLink(): string {
        return 'tabsmenu://';
    }

    getWidgetOptionsRssLink(): string {
        return 'tabwidget://';
    }

    getRegionsRssLink(): string{
        return 'regions://'
    }

    getSeeAndDoRssLink(): string{
        return 'seeanddo://'
    }

    getSettingsRssLink(): string {
        return 'settings://';
    }

    parseParameters(url: string): { categoryid?: string, subcategoryid?: string, regionid?: string } {
        // Define case-insensitive regular expression patterns for parameters in the dine:// format
    const categoryIdPattern = /(?:[?&])CategoryID=([^&]+)/i;
    const subcategoryIdPattern = /(?:[?&])SubCategoryID=([^&]+)/i;
    const regionIdPattern = /(?:[?&])RegionID=([^&]+)/i;

    // Extract parameter values using regular expressions
    const categoryIdMatch = url.match(categoryIdPattern);
    const subcategoryIdMatch = url.match(subcategoryIdPattern);
    const regionIdMatch = url.match(regionIdPattern);

    // Extract parameter values if found
    const parameters: Record<string, string | string[]> = {};

    if (categoryIdMatch) {
        const categoryidValue = categoryIdMatch[1];
        parameters['categoryid'] = isListFormat(categoryidValue) ? parseListValues(categoryidValue) : categoryidValue;
    }

    if (subcategoryIdMatch) {
        const subcategoryidValue = subcategoryIdMatch[1];
        parameters['subcategoryid'] = isListFormat(subcategoryidValue) ? parseListValues(subcategoryidValue) : subcategoryidValue;
    }

    if (regionIdMatch) {
        const regionidValue = regionIdMatch[1];
        parameters['regionid'] = isListFormat(regionidValue) ? parseListValues(regionidValue) : regionidValue;
    }

    // Helper function to check if a value is in list format
    function isListFormat(value: string): boolean {
        return /^\[.*\]$/.test(value);
    }

    // Helper function to parse list values
    function parseListValues(value: string): string[] {
        return value.slice(1, -1).split(',').map(item => item.trim());
    }

    // Return the results
    return parameters;
    }

}
