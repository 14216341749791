import { Component, Input, OnInit } from '@angular/core';

import { AlertService } from '@app/shared/services/alert.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { FavoritesService } from '@app/core/services/favorites.service';
import { ItineraryBuilderItemClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-item-client.service';
import { Listing } from '@app/core/data/Listing';
import { LoadingService } from '@app/shared/services/loading.service';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
declare function require(name:string);

export interface FavoriteEntity {
  title: string;
  imageUrl?: string;
  latitude?: number;
  longitude?: number;
  listing?: Listing;
  event?: CMSEvent;
}

@Component({
  selector: 'app-liked-listings',
  templateUrl: require('template/' + environment.template + '/html/liked-listings.component.html'),
  styles: [require('template/' + environment.template + '/styles/liked-listings.component.scss')]
})
export class LikedListingsComponent implements OnInit {

  public environment = environment;
  @Input() itineraryId: string;
  @Input() dayId: string;
  favorites: FavoriteEntity[] = [];

  constructor(
    private favoritesService: FavoritesService,
    private alertService: AlertService,
    private userApi: UsersApiService,
    private router: Router,
    private itineraryBuilder: ItineraryBuilderItemClientService,
    private loading: LoadingService,
    private analyticsService: AngularFireAnalytics,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    this.favorites = []
    this.favoritesService.getFavoritesListings().then(list => {
      list.sort((a, b) => {
        if (a.SortKey > b.SortKey) {
          return 1;
        } else if (a.SortKey < b.SortKey) {
          return -1;
        }
        return 0;
      });

      list.forEach(item => {
        console.log(item);
          this.favorites.push({
            //While listings don't have titles, this picks up events as well as listings.
            title: item.SortKey? item.SortKey: item.Title,
            imageUrl: item.Image_List && item.Image_List.split('|').length > 0 ? item.Image_List.split('|')[0] : null,
            latitude: item.Latitude,
            longitude: item.Longitude,
            listing: item,
          });
      });

      this.favoritesService.getFavoritesEvents().then(events => {
        events.forEach(item => {
          this.favorites.push({
            title: item.Title,
            imageUrl: item.Image_List && item.Image_List.split('|').length > 0 ? item.Image_List.split('|')[0] : null,
            latitude: item.Latitude,
            longitude: item.Longitude,
            event: item,
          });
        });

        this.favorites.sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          } else if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
        console.log(this.favorites)
      }).catch(err => {
        console.error(err);
      });
      console.log(this.favorites);
    }).catch(err => {
      console.error(err);
      this.alertService.presentInfoAlert('Can\'t load your listings at this moment', null, [{
        text: 'Dismiss', handler: (() => {
          this.modalController.dismiss({
            updated: false
          });
        }).bind(this)
      }]);
    });
  }

  openListing(){
    this.modalController.dismiss();
  }
  async onListingItineraryClick(listing) {
    //console.log(listing)
    // listing.Favorite = !listing.Favorite
    if (!listing.Favorite) {
      this.userApi.isLoggedIn().then(async loggedIn => {
        if (loggedIn) {
          this.favoritesService.isFavorite(listing).then(added => {
            if (added) {
              // open itinerary
              // this.router.navigateByUrl('itineraries', {
              //   relativeTo: this.route,
              //   queryParams: { t: 'Itineraries' }
              // });
            } else {
              if (listing) {
                this.analyticsService.logEvent("favorites_added",  {
                  content_type: 'favorites_added',
                  content_id: listing.ListingID,
                  item_name: listing.SortKey,
                  items: [{ name: listing.SortKey }]
                });
              }

              // add to itinerary
              this.favoritesService.favorite(listing).then(status => {
                if (status) {
                  this.ngOnInit();
                  // this.itineraryButtonText = 'Open itinerary';
                } else {
                  //console.log(`>>> favorite.then: ${status}`);
                  this.ngOnInit();
                  // this.itineraryButtonText = 'Add to itinerary';
                }
              }).catch(err => {
                //console.log(`>>> favorite.catch: ${err}`);
                // this.itineraryButtonText = 'Add to itinerary';
              });
            }
          }).catch(err => {
            //console.log(`>>> isFavoriteListing.catch: ${err}`);
          });
        } else {
          //console.log('>>> isLoggedIn: loggedIn: ' + loggedIn);
        }
      }).catch(async err => {
        //console.log('>>> isLoggedIn: ' + err);
      });
      this.ngOnInit();
    }else{
      this.userApi.isLoggedIn().then(async loggedIn => {
        if (loggedIn) {
          this.favoritesService.unfavorite(listing.ListingID).then(added => {
            if (added) {
              // open itinerary
              // this.router.navigateByUrl('itineraries', {
              //   relativeTo: this.route,
              //   queryParams: { t: 'Itineraries' }
              // });
            } else {
              if (listing) {
                this.analyticsService.logEvent("favorites_removed",  {
                  content_type: 'favorites_removed',
                  content_id: listing.ListingID,
                  item_name: listing.SortKey,
                  items: [{ name: listing.SortKey }]
                });
              }

              // add to itinerary
              this.favoritesService.unfavorite(listing.ListingID).then(status => {
                if (status) {
                  // this.itineraryButtonText = 'Open itinerary';
                  this.ngOnInit();
                } else {
                  //console.log(`>>> favorite.then: ${status}`);
                  // this.itineraryButtonText = 'Add to itinerary';
                  this.ngOnInit();
                }
              }).catch(err => {
                //console.log(`>>> favorite.catch: ${err}`);
                // this.itineraryButtonText = 'Add to itinerary';
              });
            }
          }).catch(err => {
            //console.log(`>>> isFavoriteListing.catch: ${err}`);
          });
        } else {
          //console.log('>>> isLoggedIn: loggedIn: ' + loggedIn);
        }
      }).catch(async err => {
        //console.log('>>> isLoggedIn: ' + err);
      });
      this.ngOnInit();
    }
  }

  async onItemClick(item: FavoriteEntity) {
    if (this.itineraryId){
      //console.log(item)
      await this.loading.presentLoading();
      const listingId = item.listing ? item.listing.ListingID : null;
      const eventId = item.event ? item.event.EventID : null;
      const startTime = moment().format('HH:mm:ss');
      const endTime = moment().add(1, 'h').format('HH:mm:ss');
      this.itineraryBuilder.addItemToDay(+this.itineraryId, +this.dayId, '' + listingId, '' + eventId, startTime, endTime).then(newItem => {
        this.loading.dismiss();
        if (newItem.Id) {
          newItem.Title = item.title;
          newItem.ImageUrl = item.imageUrl;
          newItem.Latitude = item.latitude;
          newItem.Longitude = item.longitude;
          this.modalController.dismiss({
            updated: true,
            itineraryItem: newItem,
          });
        } else {
          this.alertService.presentInfoAlert('Can\'t pick this listing at this moment', null, [{ text: 'Dismiss' }]);
        }
      }).catch(err => {
        console.error(err);
        this.loading.dismiss();
        this.alertService.presentInfoAlert('Can\'t pick this listing at this moment', null, [{ text: 'Dismiss' }]);
      });
    }else{
      if (item.listing) this.router.navigate(['/tabs/listings', item.listing.ListingID]);
      else this.router.navigate(['/tabs/events', item.event.EventID]);
      this.dismissModal();
    }
  }

  async dismissModal() {
    this.modalController.dismiss({
      updated: false
    });
  }

}
