import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SubCategory } from '@app/core/data/SubCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { environment } from '@env/environment';
import _, { map } from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { IFrameModalPage } from '@app/i-frame-modal/i-frame-modal.page';
declare function require(name:string);

@Component({
  selector: 'app-categories-list',
  templateUrl: require('template/' + environment.template + '/html/categories-list.component.html'),
  styles: [require('template/' + environment.template + '/styles/categories-list.component.scss')]
})
export class CategoriesListComponent implements OnInit {

  public environment = environment;  @Input()
  deepLink?: string;
  @Input() subcategories: any;
  categoryId?: number;
  categories: any[];
  isLoading: boolean;
  title: string;
  private SIOUX_FALLS_PARK_LISTING_ID = 4500;
  language: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,private modalCtrlr: ModalController,
    private dataController: CMSDataControllerService,
  ) { }

  ngOnInit() {
    this.language = this.translate.currentLang;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.deepLink = this.route.snapshot.queryParams.rssLink || this.route.snapshot.queryParams.deepLink;
    this.title = this.route.snapshot.queryParams.t;
    console.log(new Date() + '>>> ' + this.deepLink, this.title);

  }

  ionViewDidLeave(){
    this.subcategories = null;
    console.log("destroy")
  }

  ionViewDidEnter(){
    this.deepLink = this.route.snapshot.queryParams.deepLink || this.route.snapshot.queryParams.rssLink;
    console.log(this.deepLink)
    this.ngOnInit();
    console.log("ionViewDidEnter")
  }


  onItemClick(item: any) {
    console.log(item)
    if (!item.rssLink) {
      this.router.navigate(['/tabs/listings'], { queryParams: { t: item.PageName, rssLink: `listings://?subcategoryid=${item.SubCategoryID}` } });
    }else if (item.rssLink.includes("http")) {
      // falls park(sioux falls custom code)
      // window.open(item.rssLink)
      this.showIframe(item.rssLink);

      // this.router.navigate(['/tabs/listings', this.SIOUX_FALLS_PARK_LISTING_ID],
      // { queryParams: { t: item.SubCategoryName, subCategoryId: item.SubCategoryID } });
    } else {
      this.router.navigate(['/tabs/listings'], { queryParams: { t: item.PageName, rssLink: item.rssLink } });
    }
  }
  colSize(index) {
    if (this.subcategories.length % 2 && index == this.subcategories.length - 1)
      return "12";
    else
      return "6";
      //return environment.cms.navigation.itemsPerRowCategory == 1 ? "12" : "6";
  }

  async showIframe(link: string) {
    const modal = await this.modalCtrlr.create({
      component: IFrameModalPage,
      componentProps: {
        url: link
      }
    });
    await modal.present();
  }

}
